import { colors, mediaQueries, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const IMAGE_HEIGHT = 300;
export const TEXT_HEIGHT = 80;

export const Container = styled.div`
  ${mediaQueries.forTabletVerticalUp} {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  .swiper-image {
    border-radius: ${size.xs2}px;
    display: flex;
    align-items: center;
    height: ${IMAGE_HEIGHT}px;

    ${mediaQueries.forTabletVerticalUp} {
      height: auto;
    }
  }
`;

export const TextWrapper = styled.div`
  ${typography.bodyTextSmall};
  line-height: 1.375rem;
  margin-top: ${size.sm}px;
  height: ${TEXT_HEIGHT}px;

  ${mediaQueries.forTabletVerticalUp} {
    height: auto;
  }

  p {
    color: ${colors.gray4};
  }

  a {
    color: ${colors.green4};
  }

  ${mediaQueries.forTabletVerticalUp} {
    ${typography.h5Text};
    font-weight: ${typography.weight.book} !important; /* to override weight */
    word-break: break-word;
    padding-right: ${size.lg}px;
    margin-top: 0;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${mediaQueries.forDesktopUp} {
    padding: 0 ${size.xl3}px 0 ${size.md}px;
    ${typography.h3Text};
  }
`;
