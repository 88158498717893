import React from 'react';
import { ContentfulHtmlLongText } from 'utils/types';

import * as S from './styles';

export type StyledContentfulTextProps = {
  text: ContentfulHtmlLongText;
  disableTypography?: boolean;
};

const StyledContentfulText = ({
  text,
  disableTypography,
}: StyledContentfulTextProps): JSX.Element | null =>
  text?.childMarkdownRemark && text.childMarkdownRemark?.html ? (
    <S.Wrapper
      dangerouslySetInnerHTML={{ __html: text.childMarkdownRemark.html }}
      disableTypography={!!disableTypography}
    ></S.Wrapper>
  ) : null;

export default StyledContentfulText;
