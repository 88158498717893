import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import { colors, Icons, mediaQueries, size } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const NavigationButton = styled.button`
  background: none;
  display: inline-flex;
  padding: 0 ${size.xs2}px;
  border: none;
  cursor: pointer;
  border-radius: 1px;
  align-items: center;
  height: ${size.xl3}px;
  width: ${size.xl3}px;

  &:focus {
    outline: none;
  }

  ${mediaQueries.forTabletVerticalUp} {
    padding: 14px 18px;
    border: 1px solid ${colors.green5};
    border-radius: 1px;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: ${colors.teal5};
      border-color: ${colors.teal5};
      & > svg {
        color: ${colors.white};
      }
    }

    &:active {
      background-color: ${colors.teal4};
      border-color: ${colors.teal4};
      & > svg {
        color: ${colors.white};
      }
    }
  }

  &.prev-slide {
    margin-right: ${size.lg}px;
    ${mediaQueries.forDesktopUp} {
      margin-right: ${size.xl3}px;
    }
  }

  &.next-slide {
    margin-left: ${size.lg}px;
    ${mediaQueries.forDesktopUp} {
      margin-left: ${size.xl3}px;
    }
  }
`;

const IconStyle = css`
  color: ${colors.teal4};
  display: block;
  width: 15px;
  height: 20px;
`;

export const LeftIcon = styled(Icons.LeftCaret)`
  ${IconStyle}
`;

export const RightIcon = styled(Icons.RightCaret)`
  ${IconStyle}
`;
