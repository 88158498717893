import StyledContentfulText from 'components/StyledContentfulText';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import { ContentfulHtmlLongText } from 'utils/types';
import { ImageType } from 'utils/types';

import * as S from './CarouselItem.styles';

export type CarouselItemProps = {
  image: ImageType;
  itemIndex: number;
  onLinkClick: (slideNumber: number) => void;
  imageLink: string;
  text?: ContentfulHtmlLongText;
  id?: string; // contentful id used for loops. Aka: react key
};

/**
 * Component for a single carousel item.
 */
export default function CarouselItem({
  image,
  itemIndex,
  imageLink,
  text,
  onLinkClick,
}: CarouselItemProps): JSX.Element {
  const iamgeRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container>
      <S.ImageWrapper ref={iamgeRef}>
        <Link to={imageLink} onClick={() => onLinkClick(itemIndex + 1)}>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.description || ''}
            imgStyle={{ objectFit: 'cover' }}
            class="swiper-image"
          ></GatsbyImage>
        </Link>
      </S.ImageWrapper>

      <S.TextWrapper
        data-testid="text-wrapper"
        onClick={() => onLinkClick(itemIndex + 1)}
      >
        {text ? (
          <StyledContentfulText
            text={text}
            disableTypography={true}
          ></StyledContentfulText>
        ) : null}
      </S.TextWrapper>
    </S.Container>
  );
}
