import { colors, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Wrapper = styled.div<{ disableTypography: boolean }>`
  a {
    color: ${colors.teal5};
    text-decoration: none;
    font-weight: ${typography.weight.regular};

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  ${({ disableTypography }) =>
    !disableTypography &&
    `
    p {
      ${typography.bodyTextSmall}
      font-weight: ${typography.weight.light}
    }
  
    span {
      ${typography.bodyTextXSmall}
      font-weight: ${typography.weight.light}
    }
  
    a {
      font-weight: ${typography.weight.regular};
    }
    `};
`;
