import { size, colors, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

import { IMAGE_HEIGHT, TEXT_HEIGHT } from './CarouselItem/CarouselItem.styles';

export const Container = styled.div`
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .numbered-swiper {
    display: flex;

    ${mediaQueries.forTabletVerticalUp} {
      align-items: center;
    }

    &-pagination {
      width: ${size.xl1}px;
      margin-right: ${size.md}px;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      z-index: 1;

      /* Pagination has the same height as the image */
      height: ${IMAGE_HEIGHT}px;
    }

    /* Following classes are generated by swiper */
    .swiper {
      &-wrapper {
        height: ${IMAGE_HEIGHT + TEXT_HEIGHT}px;

        ${mediaQueries.forTabletVerticalUp} {
          height: auto;
        }
      }

      &-container {
        ${mediaQueries.forTabletVerticalUp} {
          height: unset;
          z-index: 0;
        }
      }

      &-pagination {
        &-bullet {
          flex: 1;
          border-left: 1.5px solid ${colors.green5};
          background-color: ${colors.white};
          color: ${colors.green5};
          border-radius: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          &-active {
            border-color: ${colors.green5};
            transition: opacity 0.5s ease-in-out;
          }
        }
      }
    }
  }
`;
