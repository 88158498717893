import { breakpoints, Container } from '@everlywell/leaves';
import CarouselItems from 'components/CarouselItems';
import { CarouselItemProps } from 'components/CarouselItems/CarouselItem';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import * as S from './HeroCarousel.styles';

export type HeroCarouselProps = {
  /**Content of the carousel */
  content: {
    carouselItems: CarouselItemProps[];
  };
};

/**
 * Hero carousel component
 */
export default function HeroCarousel({
  content: { carouselItems },
}: HeroCarouselProps): JSX.Element {
  let isMobile = false;

  if (typeof window !== 'undefined') {
    isMobile = window.innerWidth <= breakpoints.forPhoneOnly;
  }

  const trackSlideChange = (slideNumber: number) => {
    ewAnalytics.track({
      event: ANALYTICS.HOMEPAGE.HeroSlideChange,
      data: {
        label: ANALYTICS.LABELS.HOMEPAGE,
        slideNumber: slideNumber,
      },
    });
  };

  const onLinkClick = (slideNumber: number) => {
    ewAnalytics.track({
      event: ANALYTICS.HOMEPAGE.HeroSlideClick,
      data: {
        label: ANALYTICS.LABELS.HOMEPAGE,
        slideNumber: slideNumber,
      },
    });
  };

  const ConditionalContainer = ({
    children,
  }: {
    children: JSX.Element;
  }): JSX.Element => (!isMobile ? <Container>{children}</Container> : children);

  return (
    <ConditionalContainer>
      <S.Container>
        <CarouselItems
          items={carouselItems}
          onSlideChange={trackSlideChange}
          onLinkClick={onLinkClick}
          isMobile={isMobile}
        ></CarouselItems>
      </S.Container>
    </ConditionalContainer>
  );
}
