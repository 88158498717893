import { size, mediaQueries } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 ${size.lg}px;
  margin: ${size.xl1}px 0 ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin: ${size.xl4}px 0;
    padding: 0;
  }
`;
