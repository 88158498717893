import NumberedSwiperWrapper from 'components/NumberedSwiperWrapper';
import React, { useRef } from 'react';

import CarouselItem, { CarouselItemProps } from './CarouselItem/CarouselItem';
import * as S from './CarouselItems.styles';

export type CarouselItemsProps = {
  /** A set of items for each slide */
  items: CarouselItemProps[];
  /** Function called on every slide change */
  onSlideChange: (slideNumber: number) => void;
  /** Function to track clicks on a slide or a link */
  onLinkClick: (slideNumber: number) => void;
  isMobile: boolean;
};

/**
 * Wrapper for a set of carousel items.
 */
export default function CarouselItems({
  items,
  onSlideChange,
  onLinkClick,
  isMobile,
}: CarouselItemsProps): JSX.Element {
  const carouselItemRef = useRef<HTMLDivElement>(null);

  const getCarouselItems = () => {
    const carouselItems = [];
    let itemIndex = 0;

    for (const item of items) {
      carouselItems.push(
        <div ref={carouselItemRef}>
          <CarouselItem
            key={item.id}
            image={item.image}
            imageLink={item.imageLink}
            text={item.text}
            itemIndex={itemIndex}
            onLinkClick={onLinkClick}
          ></CarouselItem>
        </div>,
      );

      itemIndex++;
    }

    return carouselItems;
  };

  return (
    <S.Container>
      <NumberedSwiperWrapper
        items={getCarouselItems()}
        onSlideChange={onSlideChange}
        direction={isMobile ? 'vertical' : 'horizontal'}
      />
    </S.Container>
  );
}
