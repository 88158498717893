// Some logic here could be better with useEffect but that does not play well with the swiper libray

import { size } from '@everlywell/leaves';
import React from 'react';
import SwiperCore, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { generateKey } from 'utils/helpers';

import * as S from './styles';

SwiperCore.use([Navigation, Pagination]);

export type NumberedSwiperWrapperProps = {
  /** Images that will be shown on the slider */
  items: JSX.Element[];
  /** Event fired when the active slide changes */
  onSlideChange?: (slideNumber: number) => void;
  direction: SwiperOptions['direction'];
};

export default function NumberedSwiperWrapper({
  items,
  onSlideChange,
  direction,
}: NumberedSwiperWrapperProps): JSX.Element | null {
  const SWIPER_WRAPPER_CLASS = 'numbered-swiper';
  const PAGINATION_WRAPPER_CLASS = 'numbered-swiper-pagination';
  const PREV_SLIDE_CLASS = 'prev-slide';
  const NEXT_SLIDE_CLASS = 'next-slide';
  const DESKTOP_SPEED = 600;
  const MOBILE_SPEED = 300;

  const pagination: SwiperOptions['pagination'] = {
    clickable: true,
    el: `.${PAGINATION_WRAPPER_CLASS}`,
    renderBullet: (index: number, className: string) =>
      `<span class="${className}"><span>${index + 1}</span></span>`,
  };

  const onRealIndexChange = (swiperCore: SwiperCore) => {
    if (onSlideChange) {
      onSlideChange(swiperCore.realIndex + 1);
    }
  };

  return (
    // wrapper element to override swiper styling
    <div className={SWIPER_WRAPPER_CLASS}>
      {direction === 'vertical' ? (
        <>
          {/* placeholder element to hold pagination elements*/}
          <div
            className={PAGINATION_WRAPPER_CLASS}
            data-testid="swiper-pagination"
          ></div>
        </>
      ) : null}

      {direction === 'horizontal' ? (
        <>
          {/* Previous arrow */}
          <S.NavigationButton
            aria-label="Previous Slide"
            className={PREV_SLIDE_CLASS}
          >
            <S.LeftIcon />
          </S.NavigationButton>
        </>
      ) : null}

      <Swiper
        loop={direction === 'horizontal'}
        direction={direction}
        spaceBetween={direction === 'vertical' ? Number(size.md) : 0}
        pagination={pagination}
        navigation={{
          prevEl: `.${PREV_SLIDE_CLASS}`,
          nextEl: `.${NEXT_SLIDE_CLASS}`,
        }}
        onRealIndexChange={onRealIndexChange}
        preventInteractionOnTransition={true}
        slidesPerView="auto"
        touchReleaseOnEdges={true}
        allowTouchMove={direction === 'vertical'}
        speed={direction === 'horizontal' ? DESKTOP_SPEED : MOBILE_SPEED}
      >
        {items.map((item) => (
          <SwiperSlide key={generateKey()}>{item}</SwiperSlide>
        ))}
      </Swiper>

      {direction === 'horizontal' ? (
        <>
          {/* Next arrow */}
          <S.NavigationButton
            aria-label="Next Slide"
            className={NEXT_SLIDE_CLASS}
          >
            <S.RightIcon />
          </S.NavigationButton>
        </>
      ) : null}
    </div>
  );
}
